var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "payouts-page" },
    [
      _c(
        "section",
        {
          staticClass: "border-bottom bg-lighter md-up:sticky-top",
          staticStyle: { top: "70px" }
        },
        [
          _c("div", { staticClass: "container py-3" }, [
            _c("nav", { staticClass: "nav sm-down:flex-column" }, [
              _c(
                "span",
                {
                  staticClass:
                    "btn btn-sm btn-light text-left d-block sm-down:w-fill",
                  on: { click: _vm.createReports }
                },
                [
                  _c("i", { staticClass: "i-package float-left" }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("create_reports")))])
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "btn btn-sm btn-light text-left d-block sm-down:w-fill",
                  on: { click: _vm.setProfit }
                },
                [
                  _c("i", { staticClass: "i-percent float-left" }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("set_profit_perc")))])
                ]
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "container my-4" }, [
        _c(
          "table",
          { staticClass: "w-100" },
          [
            _c(
              "tr",
              {
                staticClass: "h5 text-left strong border-bottom position-fixed"
              },
              [
                _c("th", { staticClass: "py-2" }, [_vm._v("Username")]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("date.interval.month")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("user_type")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("income_perc")))]),
                _vm._v(" "),
                _c("th", { staticClass: "align-middle" }, [
                  _c("div", { staticClass: "d-inline-flex small w-100" }, [
                    _c("div", { staticClass: "position-relative d-flex" }, [
                      _c("span", { staticClass: "d-inline-block w-50" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("evangelist")) +
                            "\n              "
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "d-inline-block w-50" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("producer")) +
                            "\n              "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "position-relative d-flex" }, [
                      _c("span", { staticClass: "d-inline-block w-50" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("content_ad_sum")) +
                            "\n              "
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "d-inline-block w-50" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("public_ad_sum")) +
                            "\n              "
                        )
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("sum")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("request")))])
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.reports, function(report, index) {
              return _c("ReportRow", {
                key: index,
                attrs: { report: report, index: index }
              })
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("CreateReports", { ref: "create-reports" }),
      _vm._v(" "),
      _c("SetProfit", { ref: "set-profit" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }