<template>
  <main class="payouts-page">
    <section
      class="border-bottom bg-lighter md-up:sticky-top"
      style="top: 70px;"
    >
      <div class="container py-3">
        <nav class="nav sm-down:flex-column">
          <span
            class="btn btn-sm btn-light text-left d-block sm-down:w-fill"
            @click="createReports"
          >
            <i class="i-package float-left"></i>
            <span>{{ $t("create_reports") }}</span>
          </span>

          <span
            class="btn btn-sm btn-light text-left d-block sm-down:w-fill"
            @click="setProfit"
          >
            <i class="i-percent float-left"></i>
            <span>{{ $t("set_profit_perc") }}</span>
          </span>
        </nav>
      </div>
    </section>

    <div class="container my-4">
      <table class="w-100">
        <tr class="h5 text-left strong border-bottom position-fixed">
          <th class="py-2">Username</th>
          <th>{{ $t("date.interval.month") }}</th>
          <th>{{ $t("user_type") }}</th>
          <th>{{ $t("income_perc") }}</th>
          <th class="align-middle">
            <div class="d-inline-flex small w-100">
              <div class="position-relative d-flex">
                <span class="d-inline-block w-50">
                  {{ $t("evangelist") }}
                </span>
                <span class="d-inline-block w-50">
                  {{ $t("producer") }}
                </span>
              </div>
              <div class="position-relative d-flex">
                <span class="d-inline-block w-50">
                  {{ $t("content_ad_sum") }}
                </span>
                <span class="d-inline-block w-50">
                  {{ $t("public_ad_sum") }}
                </span>
              </div>
            </div>
          </th>
          <th>{{ $t("sum") }}</th>
          <th>{{ $t("request") }}</th>
        </tr>
        <ReportRow
          v-for="(report, index) in reports"
          :key="index"
          :report="report"
          :index="index"
        />
      </table>
    </div>

    <CreateReports ref="create-reports" />
    <SetProfit ref="set-profit" />
  </main>
</template>

<script>
import ReportRow from "./components/ReportRow.vue";
import CreateReports from "./components/CreateReports.vue";
import SetProfit from "./components/SetProfit.vue";

export default {
  components: {
    ReportRow,
    CreateReports,
    SetProfit
  },
  data() {
    return {
      reports: [],
      offset: 0
    };
  },
  mounted() {
    this.$navbar.name = this.$t("payouts");
    this.getAllReports();
  },
  methods: {
    async getAllReports() {
      const response = (await this.$api.v2.get("/finance/reports/list")).data;
      this.reports = response.user_reports;
      this.offset = response.new_offset;
    },
    createReports() {
      this.$refs["create-reports"].open();
    },
    setProfit() {
      this.$refs["set-profit"].open();
    }
  }
};
</script>
